<template>
  <Title v-if="!isCategoryPage"
    >{{ categoryNameEn }} - Printed with your logo | Customized by Rivers
    Promo</Title
  >

  <Meta
    name="description"
    :content="`At ${companyName} , we provide an extensive selection of custom-made promotional products known for their highly competitive pricing and industry-leading turnaround times. Explore our product catalog,, which includes notebooks, trade show items, gadgets, giveaways, and promotional merchandise.`"
  />
  <Meta
    name="keywords"
    :content="`${companyName}, Rivers, Promo, promotional, customized, customizable, trade show, promo items, product sourcing, giveaway, office supplies, toys, keychains, stress balls, bags, mugs, cups, accessories, fitness, sweatbands, hats, caps, phone accessories, USB, car, auto accessories, logo, desktop, tools, gadgets, party supplies, pet supplies, household items, Power Banks, Chargers, Smart Phone, PPE, Personal Protective Equipment, Protective Gear, Disposable Gloves, Alcohol Wipes, Face Mask`"
  />
  <div class="product-container">
    <v-row no-gutters class="px-1">
      <v-col cols="12" xs="12" sm="5">
        <div class="mt-4 small-screen">
          <h4 v-if="keyword">
            Search Results for
            <span class="fc-blue">"{{ keyword }}"</span>
          </h4>
          <p v-else>{{ categoryNameEn }}</p>
          <small class="fc-666 fs-14 fb-400">{{ total }} Results Found</small>
        </div>
      </v-col>
      <v-col cols="12" xs="12" sm="7">
        <div class="sort d-flex flex-row-reverse mt-4">
          <div class="d-sm-none">
            <v-icon
              icon="mdi-filter-menu-outline"
              size="20"
              class="mt-3 ml-2"
              @click="showFilter"
            />
          </div>
          <div class="small-screen">
            <v-select
              v-model="sortQuery"
              :items="sortOptions"
              item-title="label"
              item-value="value"
              @update:model-value="sort"
              :clearable="false"
            ></v-select>
          </div>

          <h4 class="mr-2" style="min-width: 50px">Sort By</h4>
        </div>
      </v-col>
    </v-row>

    <shopmall-drawer ref="filterDrawer" :width="780">
      <template #default>
        <shopmallProductFiltersItem @coloseFn="showFilter" />
      </template>
    </shopmall-drawer>

    <div style="min-height: 420px">
      <template v-if="showPage">
        <v-row class="product-list" v-if="products.length">
          <v-col
            v-for="(item, i) in products"
            :key="i"
            cols="6"
            xs="6"
            sm="6"
            md="4"
            lg="3"
            xl="3"
            xxl="2"
            class="mb-4 px-4"
          >
            <shopmall-product-item :productInfo="item" />
          </v-col>
        </v-row>
        <v-row v-else class="empty">
          <shopmall-empty
            :emptyImg="emptySearch"
            :width="360"
            :emptyText="`No products found, please try searching for other keywords.`"
          />
        </v-row>
      </template>
    </div>

    <v-pagination
      v-show="pageNum > 1"
      class="mt-4"
      :length="pageNum"
      :model-value="currentPage"
      size="x-small"
      active-color="primary"
      @update:model-value="goPage"
    ></v-pagination>
  </div>
</template>

<script setup>
import { productInteractor } from "~/core";
import { useProductStore } from "~/stores/index";
import shopmallProductFiltersItem from "@/components/shopmall-product-filters-item.vue";
import emptySearch from "~/assets/imgs/empty-search.svg";
const config = useRuntimeConfig();
const companyName = config.public.companyName;
const { $Utils } = useNuxtApp();
const route = useRoute();
const router = useRouter();
const store = useProductStore();
const searchForm = ref({});
const products = ref([]);
const keyword = ref("");
const categoryNameEn = ref(""); // 类目英文名
const sortQuery = ref(1);
const pageNum = ref(0);
const currentPage = ref(1); // 当前显示页
const size = ref(40); // 一页显示多少条
const total = ref(0);
const loading = ref(false);
const showPage = ref(false);
const isCategoryPage = route.path.includes("search" || "product");
const sortOptions = [
  {
    label: "New Arrival",
    value: 1
  },
  {
    label: "Most Popular",
    value: 0
  },
  {
    label: "Price Low to High",
    value: true
  },
  {
    label: "Price High to Low",
    value: false
  }
];

/**
 * 获取商品列表
 * @param {param} 请求参数
 */
const emit = defineEmits(["four2four", "showplaceholer"]);
const getList = async (param = {}) => {
  loading.value = false;

  const params = {
    ...param,
    ...searchForm.value,
    size: size.value,
    keyWord: keyword.value,
    categoryNameEn: categoryNameEn.value,
    // categoryId: keyword.value ? '' : store.accessoriesId,
    current: currentPage.value,
    orders:
      sortQuery.value !== 0
        ? sortQuery.value !== 1
          ? [
              {
                column: "price",
                asc: sortQuery.value
              }
            ]
          : [
              {
                column: "createTime",
                asc: false
              }
            ]
        : []
  };
  const [res, err] = await productInteractor.productPage(params);
  if (res === null) {
    emit("four2four");
    return;
  }
  if (res?.records) {
    products.value = res.records;
    total.value = res.total;
    pageNum.value = Math.ceil(res.total / size.value);
    loading.value = true;
  }
  showPage.value = true;
  emit("showplaceholer");
};

/**
 * 排序
 * @param {sort} 排序参数
 */
const sort = sort => {
  sortQuery.value = sort;
  currentPage.value = 1;

  let sortBy = "";
  if (sort !== 0) {
    if (sort !== 1) {
      sortBy = sort ? "High" : "Low";
    } else {
      sortBy = "newArrival";
    }
  }

  router.push({
    query: {
      ...route.query,
      page: 1,
      sortBy: sortBy
    }
  });
};

/**
 * 切换分页
 * @param {page}  页码
 */
const goPage = page => {
  currentPage.value = page;
  router.push({
    query: {
      ...route.query,
      page: page
    }
  });
};

// 筛选
const filterDrawer = ref(null);
const showFilter = () => {
  filterDrawer.value.show();
};

onMounted(() => {});

/**
 * 关键字查询
 * @param {keyword}
 */
watch(
  () => route.query,
  query => {
    searchForm.value = {};

    if (query.keyword) {
      keyword.value = query.keyword;
    } else {
      let nameEn = route.path?.split("category/")[1];
      categoryNameEn.value = $Utils.replaceDashWithSpaces(nameEn);
    }
    if (query.importsName) {
      const imprints = store.imprintMethod;
      let arr = [];

      for (let i = 0; i < imprints.length; i++) {
        if (query.importsName.includes(imprints[i].nameEn)) {
          arr.push(imprints[i].id);
        }
      }

      searchForm.value.printingWayIds = arr;
    }
    if (query.priceMin) {
      searchForm.value.priceMin = query.priceMin;
    }
    if (query.priceMax) {
      searchForm.value.priceMax = query.priceMax;
    }
    if (query.quantity) {
      searchForm.value.quantity = query.quantity;
    }
    if (query.page) {
      currentPage.value = Number(query.page);
    } else {
      currentPage.value = 1;
    }
    if (query.sortBy) {
      if (query.sortBy === "newArrival") {
        sortQuery.value = 1;
      } else {
        sortQuery.value = query.sortBy === "High" ? true : false;
      }
    } else {
      sortQuery.value = 0;
    }
    getList();
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss" scope>
.product-container {
  width: 100%;
  @media (min-width: 600px) {
    padding-left: 50px;
  }
  @media (max-width: 600px) {
    .small-screen {
      text-align: center;
      width: 100%;
    }
  }
  h4 {
    font-size: 14px;
  }
  small {
    font-size: 14px;
  }
  .sort {
    h4 {
      line-height: 40px;
    }
  }
  .pc {
    .v-input {
      max-width: 260px;
    }
  }
  .menu-title {
    background-color: #f2f2f2;
  }
  .empty {
    margin: 10% auto 10px;
  }
}

.v-pagination__list {
  justify-content: end !important;
}
</style>
